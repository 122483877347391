<template>
    <div class="main_bd">
        <div class="head_title">Waitlist Time Check</div>
        <div style="margin: 20px;">
            <a-row>
                <a-col :span="10">
                    <a-card title="Check" style="width: 100%; height: 350px">
                        <a-select default-value="lucy" v-model="sid" style="width: 300px" @change="handleChange">
                            <a-select-option :value="item.sid" v-for="item in merchant_list" v-bind:key="item.sid">
                                {{ item.sname }}
                            </a-select-option>
                        </a-select>
                        <div style="margin-top: 10px;">
                            <span style="padding-right: 10px;">Party size:</span>
                            <a-select v-model="people_num" style="width: 200px">
                                <a-select-option :value="item" v-for="item in people_num_list" v-bind:key="item">
                                    {{ item }}
                                </a-select-option>
                            </a-select>
                        </div>
                        <div style="margin-top: 10px;">
                            <span style="padding-right: 10px;">Check type:</span>
                            <a-radio-group v-model="check_type">
                                <a-radio value="seat_time">Seat Time</a-radio>
                                <a-radio value="join_time">Join Time</a-radio>
                            </a-radio-group>
                        </div>
                        <div style="margin-top: 10px;">
                            <span style="padding-right: 10px;">Target Date:</span>
                            <a-date-picker v-model="target_date" @change="onDateChange" :disabled-date="disabledDate"/>
                        </div>
                        <div style="margin-top: 10px;">
                            <span style="padding-right: 10px;">Target Time:</span>
                            <a-time-picker v-model="target_time" format="HH:mm" />
                        </div>
                        <a-button style="margin-top: 10px;" type="primary" @click="onCheck">Check</a-button>
                    </a-card>
                </a-col>
                <a-col :span="14">
                    <a-card title="Result" style="width: 100%;height: 350px">
                        <p v-for="item in result_list" v-bind:key="item">
                            <span> {{ 'History ' + ( check_type == 'seat_time' ? 'Join Time' : 'Seat Time: ') }} {{ item.time }} {{', the waiting count when joining the waitlist is ' + item.waiting_count }}</span>
                        </p>
                        <a-result v-if="is_check && result_list.length == 0" status="warning" title="No Data"></a-result>
                        <a-spin v-if="is_loading"></a-spin>
                    </a-card>
                </a-col>
            </a-row>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import VueSweetalert2 from "vue-sweetalert2";
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
    import moment from "moment-timezone";

    Vue.use(VueSweetalert2);

    export default {
        name: 'waiilist_check',
        components: {
        },
        data() {
            return {
                search: '',
                uname: window.localStorage.getItem('uname'),
                merchant_list: [],
                sid: '',
                check_type: 'seat_time',
                target_date: moment(),
                target_time: moment(new Date().getTime() + 60 * 60 * 1000),
                disabledDate: (current) => {
                    // Can not select days before today and today
                    return current && current < moment().startOf('day');
                },
                result_list: [],
                is_check: false,
                people_num: 2,
                people_num_list: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20],
                is_loading: false,
            };
        },
        beforeMount() {},
        async created() {
            this.get_minipass_merchant_list();
        },
        watch: {
        },
        mounted() {
        },
        computed: {
            ...mapGetters({}),
            ...mapState({})
        },
        methods: {
            ...mapActions({
                getMerchantTopn: 'merchant/getMerchantTopn',
                doWaitlistCheck: 'merchant/doWaitlistCheck',
            }),
            ...mapMutations({
                set_msg: 'app/APP_SET_MSG'
            }),

            async onCheck() {
                this.is_loading = true;
                this.is_check = false;
                this.result_list = [];
                
                let post_data = {
                    sid: this.sid,
                    check_type: this.check_type,
                    target_date: this.target_date.format('YYYY-MM-DD'), 
                    target_time: this.target_time.format('HH:mm'),
                    people_num: this.people_num
                };

                let res = await this.doWaitlistCheck(post_data);
                this.result_list = res.list;
                this.is_check = true;
                this.is_loading = false;
            },
            onDateChange() {
                
            },
            handleChange() {

            },
            turnPage(e) {
                this.get_minipass_merchant_list();
            },
            async get_minipass_merchant_list() {
                let { list } = await this.getMerchantTopn({
                    search: encodeURIComponent(this.search),
                    topn: 50
                });

                this.merchant_list = list;
                this.sid = this.merchant_list[0].sid;
            }
        }
    };
</script>

<style>
</style>
