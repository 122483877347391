import fetchApi from '../../kits/newFetch';
import axiosApi from '../../kits/axiosApi';

// initial state
const state = {
    city_list: [],
    guest_list: [],
    count_param: {},
    total_page: 0,
    total_count: 0,
    pass_count: 0,
    action_log: []
};

// getters
const getters = {
    get_city_list(state) {
        return state.city_list;
    },
    get_guest_list(state) {
        return state.guest_list;
    },
    get_count_param(state) {
        return state.count_param;
    },
    get_total_page(state) {
        return state.total_page;
    },
    get_total_count(state) {
        return state.total_count;
    },
    get_pass_count(state) {
        return state.pass_count;
    },
    get_action_log(state) {
        return state.action_log;
    }
};

// actions
const actions = {
    initCityData({ commit, rootState }, sid) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        fetch(aj_host + '/weapp/minipass/guest/city/get', {
            method: 'GET'
        })
            .then((res) => {
                return res.text();
            })
            .then((res) => {
                let res_data = JSON.parse(res).data;

                if (res_data.code === 100000) {
                    commit('INIT_CITY_DATA', {
                        city_list: res_data.data
                    });
                }
            });
    },
    selectGuestList({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        fetchApi(
            { commit },
            {
                request_name: 'get_guest_list',
                params: post_data,
                aj_host
            }
        )
            .then((res) => {
                if (res && res.data) {
                    commit("UPDATE_GUEST_LIST", res.data.data);
                }
            })
    },
    getGuestById({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        return new Promise((resolve, reject) => {
            axiosApi
                .get(aj_host + `/weapp/minipass/guest/get_guest_by_id/get?id=${post_data.id}`)
                .then((res) => {
                    resolve(res.data.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    updateGuestCount({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        fetchApi(
            { commit },
            {
                request_name: 'get_count_param',
                params: post_data,
                aj_host
            }
        )
            .then((res) => {
                if (res && res.data) {
                    commit("UPDATE_COUNT_PARAM", res.data);
                }
            })
    },
    getPassCount({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        fetchApi(
            { commit },
            {
                request_name: 'get_pass_count',
                params: post_data,
                aj_host
            }
        )
            .then((res) => {
                if (res && res.data) {
                    commit("UPDATE_PASS_COUNT", res.data);
                }
            })
    },
    updateBlocklist({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        fetch(aj_host + '/weapp/minipass/guest/blocklist/update?id=' +
            post_data.id +
            '&blocklist=' +
            post_data.blocklist +
            '&operate_user=' +
            post_data.operate_user,
            {
                method: 'GET'
            })
            .then((res) => {
                return res.text();
            })
            .then((res) => {
                let res_data = JSON.parse(res).data;

                if (res_data.code === 100000) {
                    commit('GUEST_BLOCKLIST_EDIT', {
                        block_obj: post_data
                    });
                    commit('app/APP_SET_MSG', { title: 'Successfully!', type: 'success' }, { root: true });
                }
            });
    },
    actionLog({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        fetchApi(
            { commit },
            {
                request_name: 'action_log',
                params: post_data,
                aj_host
            }
        )
            .then((res) => {
                if (res && res.data) {
                    commit("ACTION_LOG", res.data.data);
                }
            })
    },
    getGuestCenterList({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';

        return new Promise((resolve, reject) => {
            axiosApi
                .get(aj_host + `/weapp/minipass/guest/guest_center/list?page=` + post_data.page + '&q=' + post_data.q)
                .then((res) => {
                    resolve(res.data.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        });
    },
    getMembershipList({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';

        return new Promise((resolve, reject) => {
            axiosApi
                .get(aj_host + `/weapp/minipass/guest/membership/list?page=` + post_data.page + '&q=' + post_data.q + '&stimestamp=' + post_data.stimestamp + '&etimestamp=' + post_data.etimestamp)
                .then((res) => {
                    resolve(res.data.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        });
    }
};

// mutations
const mutations = {
    INIT_CITY_DATA(state, res_data) {
        state.city_list = res_data.city_list;
    },
    UPDATE_GUEST_LIST(state, res_data) {
        state.guest_list = res_data.guest_list;
        state.total_page = res_data.total_page;
        state.total_count = res_data.total_count;
    },
    GUEST_BLOCKLIST_EDIT: function (state, _data) {
        let guest_info = _data.block_obj;
        state.guest_list = [
            ...state.guest_list.slice(0, guest_info.index - 1),
            Object.assign({}, state.guest_list[guest_info.index - 1], {
                blocklist: guest_info.blocklist,
            }),
            ...state.guest_list.slice(guest_info.index)
        ];
    },
    UPDATE_COUNT_PARAM(state, res_data) {
        state.count_param = res_data.data;
    },
    UPDATE_PASS_COUNT(state, res_data) {
        state.pass_count = res_data.data.pass_count;
    },
    ACTION_LOG(state, res_data) {
        state.action_log = res_data.list;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
